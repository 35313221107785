import { combineReducers } from 'redux'
import loading from './loading.reducer'
import { createBrowserHistory } from 'history'
import { reducer as formReducer } from 'redux-form'
import { connectRouter } from 'connected-react-router'
import persist from './persist.reducer'
import artist from './artist.reducer'
import nft from './nft.reducer'
import pallets from './pallets.reducer'
import palletsNfts from './palletsNfts.reducer'
import notification from './notification.reducer'
import Types from '../_actions/_types'
import bottles from './bottles.reducer'
import marketplace from './marketplace.reducer'
import wineMakers from './winermaker.reducer'
import breadCrum from './breadCrum.reducer'
import shippingOrders from './shippingOrders.reducer'
import discountCodes from './discountCode.reducer'
import dropCollection from './dropCollection.reducer'
import elaConsent from './elaConsent.reducer'

export const history = createBrowserHistory()

const appReducer = combineReducers({
  loading: loading,
  persist: persist,
  form: formReducer,
  notification: notification,
  // addType: addType,
  artist: artist,
  nft: nft,
  pallets: pallets,
  palletsNfts: palletsNfts,
  bottles: bottles,
  marketplace: marketplace,
  wineMakers: wineMakers,
  breadCrum: breadCrum,
  router: connectRouter(history),
  shippingOrders: shippingOrders,
  discountCodes: discountCodes,
  dropCollection: dropCollection,
  elaConsent: elaConsent,
})
const rootReducer = (state, action) => {
  if (action.type === Types.RESET_PERSIST_ON_LOGOUT) {
    state = undefined
  }
  return appReducer(state, action)
}

export default rootReducer
